<!--
 * @Author: your name
 * @Date: 2020-10-19 15:58:31
 * @LastEditTime: 2020-10-27 17:33:12
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \GrandHyattc:\Users\25740\Desktop\jciweb\src\views\AuthPages\SignIn1.vue
-->
<template>
  <div class="SignIn1">
    <h1 class="title">Delete Account</h1>
    <div class="mt-4">
      <div class="form-group">
        <label for="exampleInputEmail1">{{
          $t("Login.f65@email_address")
          }}</label>
        <input type="email" class="form-control mb-0" id="exampleInputEmail1" :placeholder="$t('Login.27e@enter_email')"
          v-model="form.email" />
      </div>
      <div class="form-group passwordInt">
        <label for="exampleInputPassword1">{{
          $t("Login.dfc@password")
          }}</label>
        <input type="password" class="form-control psd mb-0" id="exampleInputPassword1"
          :placeholder="$t('Login.dfc@password')" v-model="form.password" ref="password" />
        <i ref="eye" v-if="!isIEbrowser" class="ri-eye-line" @click="changeType"></i>
      </div>
      <div class="d-inline-block w-100">
        <button type="submit" @click="deleteAccount" @keyup.enter="deleteAccount" class="btn btn-primary float-right">
          Delete
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'DeleteAccount',
    data: () => ({
      form: {
        email: '',
        password: '',
      },
      isIEbrowser: false,
    }),

    mounted() {
      this.IEVersion()
    },

    methods: {
      deleteAccount() {
        this.$confirm("Are you sure you want to delete the account?", {
          cancelButtonText: "Cancel",
          confirmButtonText: "Confirm",
          type: "warning",
        }).then(() => {
          this.$http.deleteAccount({ ...this.form }).then((res) => {
            if (res.status == 200) {
              this.$message.success(res.message)
              this.form.email = ''
              this.form.password = ''
            }
          })
        });
      },

      IEVersion() {
        let userAgent = navigator.userAgent // 取得浏览器的userAgent字符串
        let isIE = userAgent.indexOf('compatible') > -1 // 判断是否IE浏览器
        let isEdge = userAgent.indexOf('Edg') > -1 // 判断是否IE的Edge浏览器
        if (isIE || isEdge) {
          this.isIEbrowser = true
        }
      },

      changeType() {
        if (this.$refs.password.type == 'text') {
          this.$refs.password.type = 'password'
          this.$refs.eye.style.color = '#ccc'
        } else {
          this.$refs.password.type = 'text'
          this.$refs.eye.style.color = '#50b5ff'
        }
      },

    }

  }
</script>
<style lang="scss" scoped>
  @media (max-width: 578px) {
    .SignIn1 {
      /* padding-top: 40px; */
    }
  }

  .SignIn1 {
    .messageError {
      margin-top: 20px;
      margin-bottom: 20px;
      border-radius: 5px;
      padding: 10px;
      background: #fffcf3;
      color: #ffba68;
    }

    .title {
      margin-bottom: 83px;
    }

    .passwordInt {
      position: relative;
    }

    .ri-eye-line {
      position: absolute;
      top: 43px;
      right: 10px;
      color: #ccc;
    }

    .codeGroup {
      .codeImg {
        width: 20%;
        height: 45px;
      }

      .codeInt {
        padding: 0 15px;
        width: 40%;
        height: 45px;
        line-height: 45px;
        background: transparent;
        border: 1px solid #ced4da;
        border-radius: 10px;
      }
    }
  }
</style>